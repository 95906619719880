import {
  deleteWorkspacesByID,
  saveWorkspace,
  AddComponentToWorkspaceById,
  RemoveComponentToWorkspaceById,
} from "../../../data/workspace-requests"
import { returnWsDataObject } from "../../../hooks/use-get-user"
import { setItem, stringifyObj } from "../../../utils/token-helpers"
import { IUserProps, USER_KEY } from "../user-context"

export async function saveNewWorkspace(newName: string, token: string) {
  try {
    const newData = JSON.stringify({
      components: [],
    })

    const workspaceState = JSON.stringify({
      name: newName,
      data: newData,
    })

    const { id, name, data } = await saveWorkspace(workspaceState, token)

    return { id, name, data }
  } catch (error) {
    console.error(error)
  }
}

export async function addComponentToWorkspaceInDb(workspaceId: string, component: any, token: string) {
  try {
    await AddComponentToWorkspaceById(workspaceId, component, token)
  } catch (error) {
    console.error(error)
  }
}
export async function removeComponentFromWorkspace(workspaceId: string, componentId: any, token: string) {
  try {
    await RemoveComponentToWorkspaceById(workspaceId, componentId, token)
  } catch (error) {
    console.error(error)
  }
}

export async function removeWorkspace(id: string, userState: IUserProps) {
  try {
    const resp = await deleteWorkspacesByID(id, userState.token)
    return resp
  } catch (error) {
    console.error(error)
  }
}

// export function saveAndReturnState(newState: any) {
//   const stateString = JSON.stringify(newState)
//   const encryptedUser = encryptData(stateString)
//   const compressedState = LZString.compress(encryptedUser)
//   try {
//     setItem(USER_KEY, stringifyObj({ encryptedData: compressedState }))
//   } catch (error) {
//     console.error("Failed to set item in local storage:", error)
//   }
//   return newState
// }

export function saveAndReturnState(newState: any) {
  try {
    setItem(USER_KEY, stringifyObj(newState))
  } catch (error) {
    console.error("Failed to set item in local storage:", error)
  }
  return newState
}

export function addComponentToWorkspace(workspace, component) {
  const d = returnWsDataObject(workspace?.data || {})

  d.components = [...(d?.components || []), component]

  return { ...workspace, data: d }
}
export function removeComponentToSelectedWorkspace(workspace, componentId) {
  const wsData = returnWsDataObject(workspace?.data || {})

  wsData.components = wsData.components.filter(comp => comp.meta.id !== componentId)

  return { ...workspace, data: wsData }
}
