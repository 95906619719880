import { USER_KEY } from "../contexts/user/user-context"
import { useFirebase } from "../hooks/use-firebase"
import { isBrowser } from "./is-browser"
import CryptoJS from "crypto-js"

export function setItem(key: string, value: any) {
  if (isBrowser() && value) {
    window.localStorage.setItem(key, value)
  }
}

export function getItem(key: string): any {
  if (isBrowser()) {
    const token = window.localStorage.getItem(key)
    if (token) {
      return token
    }
  }

  return null
}

export function encryptData(data: string): string {
  const secretPasskey = process.env.FIREBASE_API_KEY
  if (!secretPasskey) {
    throw new Error("Secret passkey not found in environment variables")
  }
  return CryptoJS.AES.encrypt(data, secretPasskey).toString()
}

export function decryptData(encryptedData: string): string {
  const secretPasskey = process.env.FIREBASE_API_KEY
  if (!secretPasskey) {
    throw new Error("Secret passkey not found in environment variables")
  }
  const bytes = CryptoJS.AES.decrypt(encryptedData, secretPasskey)
  return bytes.toString(CryptoJS.enc.Utf8)
}

export function stringifyObj(val: object) {
  return JSON.stringify(val)
}
export function parseObj(val: string) {
  return JSON.parse(val)
}

export function setUserTokenToLocalStorage(token: string) {
  const user = parseObj(getItem(USER_KEY))

  const newUser = { ...user, token }

  setItem(USER_KEY, stringifyObj(newUser))
}
export function getUserTokenFromLocalStorage() {
  const user = parseObj(getItem(USER_KEY))

  return user?.token || ""
}

export async function getUpdatedTokenAsync() {
  const { auth } = useFirebase()
  const currentUser = auth.currentUser
  /* eslint-disable  @typescript-eslint/no-unused-vars */
  return new Promise((resolve, reject) => {
    if (currentUser) {
      const expire = getExpiration()
      const refresh = (Date.now() - expire) / 1000 > 60 * 55
      const newToken = currentUser.getIdToken(refresh).then(accessToken => {
        setUserTokenToLocalStorage(accessToken)
        updateExpiration()
        return accessToken
      })
      resolve(newToken)
    }
  })
}

export function getExpiration() {
  let ex = getItem("expiration")
  if (!ex) {
    updateExpiration()
    ex = getItem("expiration")
  }
  return ex
}

export function updateExpiration() {
  setItem("expiration", Date.now())
}
