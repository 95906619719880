exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-keys-tsx": () => import("./../../../src/pages/admin-keys.tsx" /* webpackChunkName: "component---src-pages-admin-keys-tsx" */),
  "component---src-pages-altcoin-volatility-calibration-tsx": () => import("./../../../src/pages/altcoin-volatility-calibration.tsx" /* webpackChunkName: "component---src-pages-altcoin-volatility-calibration-tsx" */),
  "component---src-pages-defi-lyra-[network]-[symbol]-[address]-tsx": () => import("./../../../src/pages/defi/lyra/[network]/[symbol]/[address].tsx" /* webpackChunkName: "component---src-pages-defi-lyra-[network]-[symbol]-[address]-tsx" */),
  "component---src-pages-defi-lyra-[network]-[symbol]-tsx": () => import("./../../../src/pages/defi/lyra/[network]/[symbol].tsx" /* webpackChunkName: "component---src-pages-defi-lyra-[network]-[symbol]-tsx" */),
  "component---src-pages-defi-portfolio-analyzer-[address]-tsx": () => import("./../../../src/pages/defi/portfolio-analyzer/[address].tsx" /* webpackChunkName: "component---src-pages-defi-portfolio-analyzer-[address]-tsx" */),
  "component---src-pages-defi-portfolio-analyzer-tsx": () => import("./../../../src/pages/defi/portfolio-analyzer.tsx" /* webpackChunkName: "component---src-pages-defi-portfolio-analyzer-tsx" */),
  "component---src-pages-defi-squeeth-tsx": () => import("./../../../src/pages/defi/squeeth.tsx" /* webpackChunkName: "component---src-pages-defi-squeeth-tsx" */),
  "component---src-pages-dvol-index-[symbol]-tsx": () => import("./../../../src/pages/dvol-index/[symbol].tsx" /* webpackChunkName: "component---src-pages-dvol-index-[symbol]-tsx" */),
  "component---src-pages-forgot-password-tsx": () => import("./../../../src/pages/forgot-password.tsx" /* webpackChunkName: "component---src-pages-forgot-password-tsx" */),
  "component---src-pages-ftx-move-tsx": () => import("./../../../src/pages/ftx-move.tsx" /* webpackChunkName: "component---src-pages-ftx-move-tsx" */),
  "component---src-pages-futs-perps-[exchange]-future-rolls-tsx": () => import("./../../../src/pages/futs-perps/[exchange]/future-rolls.tsx" /* webpackChunkName: "component---src-pages-futs-perps-[exchange]-future-rolls-tsx" */),
  "component---src-pages-futs-perps-[exchange]-futures-instrument-name-tsx": () => import("./../../../src/pages/futs-perps/[exchange]/futures/[instrumentName].tsx" /* webpackChunkName: "component---src-pages-futs-perps-[exchange]-futures-instrument-name-tsx" */),
  "component---src-pages-futs-perps-[exchange]-futures-tsx": () => import("./../../../src/pages/futs-perps/[exchange]/futures.tsx" /* webpackChunkName: "component---src-pages-futs-perps-[exchange]-futures-tsx" */),
  "component---src-pages-futs-perps-[exchange]-perpetuals-instrument-name-tsx": () => import("./../../../src/pages/futs-perps/[exchange]/perpetuals/[instrumentName].tsx" /* webpackChunkName: "component---src-pages-futs-perps-[exchange]-perpetuals-instrument-name-tsx" */),
  "component---src-pages-futs-perps-[exchange]-perpetuals-tsx": () => import("./../../../src/pages/futs-perps/[exchange]/perpetuals.tsx" /* webpackChunkName: "component---src-pages-futs-perps-[exchange]-perpetuals-tsx" */),
  "component---src-pages-futs-perps-global-[symbol]-tsx": () => import("./../../../src/pages/futs-perps/global/[symbol].tsx" /* webpackChunkName: "component---src-pages-futs-perps-global-[symbol]-tsx" */),
  "component---src-pages-greeks-[symbol]-tsx": () => import("./../../../src/pages/greeks/[symbol].tsx" /* webpackChunkName: "component---src-pages-greeks-[symbol]-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-lyra-portfolio-manager-tsx": () => import("./../../../src/pages/lyraPortfolioManager.tsx" /* webpackChunkName: "component---src-pages-lyra-portfolio-manager-tsx" */),
  "component---src-pages-options-[exchange]-[symbol]-currency-relative-vol-tsx": () => import("./../../../src/pages/options/[exchange]/[symbol]/currency-relative-vol.tsx" /* webpackChunkName: "component---src-pages-options-[exchange]-[symbol]-currency-relative-vol-tsx" */),
  "component---src-pages-options-[exchange]-[symbol]-current-[instrument]-tsx": () => import("./../../../src/pages/options/[exchange]/[symbol]/current/[instrument].tsx" /* webpackChunkName: "component---src-pages-options-[exchange]-[symbol]-current-[instrument]-tsx" */),
  "component---src-pages-options-[exchange]-[symbol]-current-tsx": () => import("./../../../src/pages/options/[exchange]/[symbol]/current.tsx" /* webpackChunkName: "component---src-pages-options-[exchange]-[symbol]-current-tsx" */),
  "component---src-pages-options-[exchange]-[symbol]-historic-tsx": () => import("./../../../src/pages/options/[exchange]/[symbol]/historic.tsx" /* webpackChunkName: "component---src-pages-options-[exchange]-[symbol]-historic-tsx" */),
  "component---src-pages-options-[exchange]-[symbol]-options-scanner-tsx": () => import("./../../../src/pages/options/[exchange]/[symbol]/options-scanner.tsx" /* webpackChunkName: "component---src-pages-options-[exchange]-[symbol]-options-scanner-tsx" */),
  "component---src-pages-options-[exchange]-[symbol]-options-scanner-uni-trade-tsx": () => import("./../../../src/pages/options/[exchange]/[symbol]/options-scanner/[uniTrade].tsx" /* webpackChunkName: "component---src-pages-options-[exchange]-[symbol]-options-scanner-uni-trade-tsx" */),
  "component---src-pages-options-[exchange]-paradigm-block-sniffer-[symbol]-tsx": () => import("./../../../src/pages/options/[exchange]/paradigm/block-sniffer/[symbol].tsx" /* webpackChunkName: "component---src-pages-options-[exchange]-paradigm-block-sniffer-[symbol]-tsx" */),
  "component---src-pages-options-[exchange]-paradigm-block-sniffer-[symbol]-uni-trade-tsx": () => import("./../../../src/pages/options/[exchange]/paradigm/block-sniffer/[symbol]/[uniTrade].tsx" /* webpackChunkName: "component---src-pages-options-[exchange]-paradigm-block-sniffer-[symbol]-uni-trade-tsx" */),
  "component---src-pages-options-global-[symbol]-tsx": () => import("./../../../src/pages/options/global/[symbol].tsx" /* webpackChunkName: "component---src-pages-options-global-[symbol]-tsx" */),
  "component---src-pages-performance-[symbol]-tsx": () => import("./../../../src/pages/performance/[symbol].tsx" /* webpackChunkName: "component---src-pages-performance-[symbol]-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-real-vol-tsx": () => import("./../../../src/pages/real-vol.tsx" /* webpackChunkName: "component---src-pages-real-vol-tsx" */),
  "component---src-pages-sign-up-tsx": () => import("./../../../src/pages/sign-up.tsx" /* webpackChunkName: "component---src-pages-sign-up-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-workspaces-tsx": () => import("./../../../src/pages/workspaces.tsx" /* webpackChunkName: "component---src-pages-workspaces-tsx" */),
  "component---src-pages-yields-[exchange]-[symbol]-tsx": () => import("./../../../src/pages/yields/[exchange]/[symbol].tsx" /* webpackChunkName: "component---src-pages-yields-[exchange]-[symbol]-tsx" */)
}

